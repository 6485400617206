import React, { Component, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import "../i18n";

import Nav from "./Nav";
import { Footer } from "./Footer";

const Home = React.lazy(() => import("../pages/Home"));
const Impressum = React.lazy(() => import("../pages/Impressum"));
const Datenschutz = React.lazy(() => import("../pages/Datenschutz"));

// const Legal = React.lazy(() => import('../pages/Legal'));

export default class MainRouter extends Component {
  render() {
    return (
      <>
        <Helmet>
          <html lang="de" />
          <title>See Restaurant Saag Ja by Hubert Wallner</title>
          <meta charset="utf-8" />
          <meta name="author" content="Synelution GmbH Klagenfurt" />
          <meta name="description" content="" />
        </Helmet>

        <Suspense fallback={null}>
          <Nav />
          <div className="main-content">
            <Switch>
              {/* <Route exact path="/legal/:slug" component={Legal} /> */}
              <Route exact path="/" component={Home} />
              <Route exact path="/impressum" component={Impressum} />
              <Route exact path="/datenschutz" component={Datenschutz} />
            </Switch>
            {/* <Footer /> */}
          </div>
        </Suspense>
      </>
    );
  }
}
