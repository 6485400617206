import React, { useContext, useState } from "react";
import { Link } from 'react-router-dom';
import { staticDataContext, legalLinks, mainLinks } from 'config';
import { useTranslation } from "react-i18next";


import logo from 'assets/logo.svg';

const Footer = () => {
  const { t, i18n } = useTranslation();
  const [lng, setLng] = useState("en");

  const changeLanguage = (lng) => {
    setLng(lng);
    i18n.changeLanguage(lng);
  };

  const { address, email, tel, companyName } = useContext(staticDataContext);
  return (
    <>
      <div className="footer">
        <div
          className="row justify-center flex-align-center"
          style={{ paddingTop: "5%" }}
        >
          <div className="col-12 align-center">
            <p style={{ marginBottom: "7px" }}>
              <span className="bold">Herbert Ospelt Anstalt</span> |
              Schaanerstrasse 79 | FL-9487 Bendern
            </p>
            <p className="nomb">
              Tel +41 58 377 10 00 | Fax +41 58 377 14 00 |{" "}
              <a className="simple-hover accent" href="mailto:info@ospelt.com">
                info@ospelt.com
              </a>
            </p>
          </div>

          <div className="col-12 align-center" style={{ paddingTop: "2%" }}>
            <span
              className="simple-hover rlySpecialLink h5"
              style={{ marginRight: "30px" }}
            >
              <Link className="museo" to="/">
                Home
              </Link>
            </span>
            <span
              className="simple-hover rlySpecialLink h5"
              style={{ marginRight: "30px" }}
            >
              <Link className="museo" to="/impressum">
                Impressum
              </Link>
            </span>
            <span className="simple-hover rlySpecialLink h5">
              <Link className="museo" to="/datenschutz">
                Datenschutz
              </Link>
            </span>
          </div>

          <div
            className="col-12 align-center"
            style={{ paddingTop: "1%", paddingBottom: "2%" }}
          >
            <p className="small">
              <span
                className="simple-hover pointer"
                style={{ marginRight: "30px" }}
                onClick={() => changeLanguage("de")}
              >
                DE
              </span>
              <span
                className="simple-hover pointer"
                style={{ marginRight: "30px" }}
                onClick={() => changeLanguage("en")}
              >
                EN
              </span>
              <span
                className="simple-hover pointer"
                style={{ marginRight: "30px" }}
                onClick={() => changeLanguage("es")}
              >
                ES
              </span>
              <span
                className="simple-hover pointer"
                style={{ marginRight: "30px" }}
                onClick={() => changeLanguage("fr")}
              >
                FR
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;