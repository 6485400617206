import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { NavButton } from "./NavButton";
import { NavElement } from "./NavElement";
import { mainLinks } from "config";
import logo from "assets/ospelt-logo.png";

const Nav = () => {
  const { t, i18n } = useTranslation();
  const [lng, setLng] = useState("en");
  const lngArray = ["de", "en", "es", "fr"];

  const changeLanguage = (lng) => {
    setLng(lng);
    i18n.changeLanguage(lng);
  };

  

  return (
    <React.Fragment>
      <nav className="nav">
        <div className="row">
          <div className="col-6">
            <div className="flexbox justify-start">
              <div className="logo">
                <Link to="/">
                  <img alt="Ospelt Logo" src={logo} />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="flexbox justify-end">
              <div className="language-box">
                <div className="selector">
                  <div className="active pointer">
                    <p className="uppercase">{lng}</p>
                  </div>

                  <div>
                    {lngArray.map((posLNG) => {
                      return posLNG !== lng ? (
                        <p
                          value={posLNG}
                          className="pointer uppercase select nom"
                          onClick={() => changeLanguage(posLNG)}
                        >
                          {posLNG}
                        </p>
                      ) : null;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Nav;
